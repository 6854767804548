import React from 'react'
import ProgressiveImage from 'gatsby-image'
import { Box, Flex, Heading, Text, Section, Container } from 'components'

const UserStory = ({ gatsbyFluidImage, title, description, name }) => {
  return (
    <Box>
      <Box
        variant="card.secondary"
        my={2}
        pt={[6]}
        px={[4, 5]}
        pb={4}
        sx={{ position: 'relative' }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['auto', 'auto auto'],
            justifyItems: 'center',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Box
            sx={{
              width: ['175px', '250px'],
              height: ['175px', '250px'],
              borderRadius: 'circle',
              border: '1px solid',
              borderColor: 'colorBlue25',
              bg: 'colorBlue10',
              overflow: 'hidden',
            }}
          >
            {gatsbyFluidImage && (
              <ProgressiveImage fluid={gatsbyFluidImage} alt={name} />
            )}
          </Box>
          <Box>
            {title && (
              <Heading.H4 mb={3} fontSize="21px" color="colorBlue100">
                {title}
              </Heading.H4>
            )}
            <Text fontSize={2} mb="auto" color="colorBlue100">
              {description}
            </Text>
            {name && (
              <Flex alignItems="center">
                <Text
                  flexGrow="1"
                  fontSize={2}
                  fontWeight="bold"
                  color="colorBlue100"
                >
                  {name}
                </Text>
              </Flex>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UserStory
