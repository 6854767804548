import React from 'react'
import ProgressiveImage from 'gatsby-image'

import { Section, Container, Box, Heading, Text, Button } from 'components'

import { Mail, Phone } from 'icons'
import LeadCTAButton from 'components/LeadCTAButton'

const Contact = ({
  title = 'Unverbindlich anfragen',
  subheading = 'Wir freuen uns über Ihre Anfrage',
  gatsbyFluidImage,
  email,
  textBlueButton = 'Jetzt Beratungsgespräch vereinbaren',
  leadModalTypeBlueButton = 'teilverkauf',
  ctaBlueButtonID,
  textPinkButton = 'Jetzt unverbindlich anfragen',
  leadModalTypePinkButton = 'teilverkauf',
  ctaPinkButtonID,
  directContactText = 'Gerne können Sie uns auch direkt kontaktieren:',
  phoneNumber,
  imagePosition,
  imageTransform,
  textInsteadOfPinkCTA,
  backgroundColor = 'colorBlue2',
}) => {
  return (
    <Section
      spacing="large"
      sx={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor,
        position: 'relative',
        minHeight: ['unset', null, null, null, '900px'],
      }}
      pb="200px"
    >
      <Container>
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: 'large',
            padding: [3, 6],
            zIndex: 2,
            maxWidth: '575px',
            position: 'relative',
            boxShadow: 'md',
          }}
        >
          <Section.Header>
            <Heading.H2 textAlign="center" sx={{ color: 'colorBlue90' }}>
              {title}
            </Heading.H2>
            <Text
              textAlign="center"
              sx={{ color: 'colorBlue90', fontSize: 3 }}
              mt={[3]}
            >
              {subheading}
            </Text>
          </Section.Header>

          <Section.Body
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <LeadCTAButton
              py={1}
              variant="primary"
              leadModalType={leadModalTypeBlueButton}
              ctaButtonID={ctaBlueButtonID}
              sx={{
                backgroundColor: 'blue',
                width: '100%',
                marginTop: [0, 2],
              }}
            >
              {textBlueButton}
            </LeadCTAButton>
            {textInsteadOfPinkCTA ? (
              <Text mt={4}>{textInsteadOfPinkCTA}</Text>
            ) : (
              <LeadCTAButton
                variant="main"
                leadModalType={leadModalTypePinkButton}
                ctaButtonID={ctaPinkButtonID}
                sx={{
                  width: '100%',
                  mx: ['auto', 0],
                  marginTop: [3, 4],
                }}
              >
                {textPinkButton}
              </LeadCTAButton>
            )}
            <Text
              sx={{
                fontSize: '1.375rem',
                fontWeight: 'bold',
                color: 'colorBlue100',
                marginTop: 8,
                textAlign: 'center',
              }}
            >
              {directContactText}
            </Text>
            <Box
              mt={5}
              sx={{
                display: 'flex',
                flexDirection: ['column', null, 'row'],
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  width: ['100%', 'auto'],
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  py={1}
                  as="a"
                  href={`mailto:${email}`}
                  target="_blank"
                  variant="outline"
                  sx={{
                    width: ['100%', 'auto'],
                    maxWidth: 400,
                    minWidth: 220,
                  }}
                >
                  <Box as={Mail} width={14} mr={2} />
                  {email}
                </Button>
              </Box>
              <Box sx={{ width: '40px', display: ['none', 'block'] }} />
              <Box
                sx={{
                  width: ['100%', 'auto'],
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  py={1}
                  as="a"
                  href={`tel:${phoneNumber}`}
                  target="_blank"
                  variant="outline"
                  sx={{ width: ['100%', 'auto'], maxWidth: 400, minWidth: 220 }}
                  mt={[3, null, 0]}
                >
                  <Box as={Phone} width={14} mr={2} />
                  {phoneNumber}
                </Button>
              </Box>
            </Box>
          </Section.Body>
        </Box>
        {gatsbyFluidImage && (
          <Box
            sx={{
              width: '100%',
              height: ['250px', '100%'],
              position: 'absolute',
              left: 0,
              bottom: 0,
              zIndex: 1,
              '&:after': {
                display: 'block',
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                left: 0,
                height: ['60px', '100%'],
                background: [
                  'linear-gradient(0deg, rgba(249, 246, 248, 0) 0%, #F9F6F8 100%)',
                  'linear-gradient(270deg, rgba(249, 246, 248, 0) 0%, #F9F6F8 70%)',
                ],
              },
              ...(imagePosition && {
                '--image-position': imagePosition,
              }),
              ...(imageTransform && {
                '--image-transform': imageTransform,
              }),
            }}
          >
            <ProgressiveImage
              fluid={gatsbyFluidImage}
              imgStyle={{
                userSelect: 'none',
                pointerEvents: 'none',
                ...(imagePosition && {
                  objectPosition: 'var(--image-position)',
                }),
                ...(imageTransform && {
                  transform: 'var(--image-transform)',
                }),
              }}
              style={{ height: '100%' }}
            />
          </Box>
        )}
      </Container>
    </Section>
  )
}

export default Contact
