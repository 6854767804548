import React from 'react'

import { Section, Container, Heading, Text } from 'components'
import LeadCTAButton from 'components/LeadCTAButton'

import FeatureBoxes from 'screens/Home/components/FeatureBoxes'

const Flexibility = ({
  title = 'Bambus verschafft Ihnen finanzielle Flexibilität',
  subheading = 'Lohnende Gedanken',
  subtitle = 'Wofür hat Ihnen bisher die notwendige Liquidität gefehlt?',
  items = [],
  ctaText = 'Anfrage starten',
  leadModalType = 'teilverkauf',
  ctaButtonID,
  backgroundColor = 'colorBlue2',
}) => {
  return (
    <Section spacing="large" sx={{ backgroundColor }}>
      <Container>
        <Section.Header>
          <Heading.H2 textAlign="center" sx={{ color: 'colorBlue90' }}>
            {title}
          </Heading.H2>
          {subheading && (
            <Text
              textAlign="center"
              sx={{ color: 'colorBlue90', fontSize: 4, fontWeight: 'bold' }}
              mt={4}
            >
              {subheading}
            </Text>
          )}
          {subtitle && (
            <Text
              textAlign="center"
              sx={{ color: 'colorBlue90' }}
              mt={subheading ? 3 : 4}
            >
              {subtitle}
            </Text>
          )}
        </Section.Header>

        <Section.Body>
          <FeatureBoxes items={items} />
        </Section.Body>
        <Section.Footer sx={{ textAlign: 'center' }} mt={[6]}>
          <LeadCTAButton
            variant="main"
            leadModalType={leadModalType}
            ctaButtonID={ctaButtonID}
            sx={{
              width: ['100%', 'auto'],
              maxWidth: 400,
              mx: ['auto', 0],
            }}
          >
            {ctaText}
          </LeadCTAButton>
        </Section.Footer>
      </Container>
    </Section>
  )
}

export default Flexibility
